/**
 * Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

.container {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  justify-content: start;
  align-items: start;
  margin-left: -50px;
}

.header {
  display: grid;
  gap: 5px;
  margin-bottom: 15px;
}

.title {
  font-weight: 600;
  font-family: 'Public Sans', sans-serif;
}

.anchor {
  visibility: hidden;
}

.container:hover .anchor {
  visibility: visible;
}

.icon {
  transform: rotate(-45deg);
}
