/**
 * Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.container {
  display: grid;
  justify-items: center;
  align-content: start;
  margin-top: 20vh;
}

.logo {
  border-radius: 50%;
  height: 80px;
  margin-bottom: 20px;
}

.form {
  display: grid;
  width: 100%;
  max-width: 900px;
  margin: auto;
  text-align: center;
  gap: 10px;
}

.comparison {
  cursor: pointer;
}

.comparison:hover {
  text-decoration: underline;
}
