/**
 * Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.loading {
  display: grid;
  gap: 20px;
  justify-items: center;
  align-content: center;
}
