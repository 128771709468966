/**
 * Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.dateTag {
  padding: 10px;
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px,
    rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  border-radius: 5px;
}

.labelContainer {
  display: flex;
  justify-content: center;
}
