/**
 * Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.row[data-metrics='ELITE'] {
  font-weight: 700;
  background-color: var(--color-team-elite);
  color: var(--color-team-elite-contrast-color);
}

.row[data-metrics='STRONG'] {
  font-weight: 700;
  background-color: var(--color-team-strong);
  color: var(--color-team-strong-contrast-color);
}

.row[data-metrics='FAIR'] {
  font-weight: 700;
  background-color: var(--color-team-fair);
  color: var(--color-team-fair-contrast-color);
}

.row[data-metrics='NEEDS FOCUS'] {
  font-weight: 700;
  background-color: var(--color-team-need-focus);
  color: var(--color-team-need-focus-contrast-color);
}

.cell {
  color: inherit;
}
