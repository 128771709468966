/**
 * Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.title {
  margin: 0px 0px 40px;
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.5rem;
  font-family: 'Public Sans', sans-serif;
}

.repo {
  color: white;
  background-color: var(--color-main);
  padding: 8px;
  border-radius: 3px;
}

.details {
  display: grid;
  gap: 40px;
}

.resultContainer {
  display: grid;
  gap: 13px;
  grid-template-columns: 1fr 2fr;
  margin-top: 20px;
}

.resultContainerSingle {
  grid-template-columns: 1fr;
}

.chart {
  margin-top: 20px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.benchmarksTooltip {
  padding: 10px;
  display: grid;
  gap: 10px;
}
