/**
 * Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.container {
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}
