/**
 * Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

.container {
  padding-left: 30px;
  margin-top: 0;
}

.benchmark {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.circle {
  width: 15px;
  height: 15px;
}

.eliteBenchmark {
  fill: var(--color-team-elite);
  color: var(--color-team-elite-contrast-color);
}

.strongBenchmark {
  fill: var(--color-team-strong);
  color: var(--color-team-strong-contrast-color);
}

.fairBenchmark {
  fill: var(--color-team-fair);
  color: var(--color-team-fair-contrast-color);
}

.needsFocusBenchmark {
  fill: var(--color-team-need-focus);
  color: var(--color-team-need-focus-contrast-color);
}
