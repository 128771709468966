/**
 * Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.averageMergeTime {
  display: grid;
  gap: 5px;
  justify-items: center;
  align-content: center;
}

.averageEliteMergeTime {
  font-weight: 700;
  background-color: var(--color-team-elite) !important;
  color: var(--color-team-elite-contrast-color) !important;
}

.averageStrongMergeTime {
  font-weight: 700;
  background-color: var(--color-team-strong) !important;
  color: var(--color-team-strong-contrast-color) !important;
}

.averageFairMergeTime {
  font-weight: 700;
  background-color: var(--color-team-fair) !important;
  color: var(--color-team-fair-contrast-color) !important;
}

.averageNeedsFocusMergeTime {
  font-weight: 700;
  background-color: var(--color-team-need-focus) !important;
  color: var(--color-team-need-focus-contrast-color) !important;
}

.cell {
  color: unset
}
