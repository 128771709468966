/**
 * Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

.container {
  padding: 15px;
  background-color: white;
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px,
    rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  border-radius: 5px;
  overflow-x: scroll;
  /* for Internet Explorer, Edge */
  -ms-overflow-style: none;
  /* for Firefox */
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  /* for Chrome, Safari, and Opera */
  display: none;
}

.containerWithTitle {
  display: grid;
  gap: 8px;
  align-content: flex-start;
  grid-template-rows: auto 1fr;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
}

.title {
  padding: 9px 0;
  font-weight: 500;
  font-size: 1.2rem;
  font-family: 'Public Sans', sans-serif;
}
