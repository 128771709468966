/**
 * Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

.container {
  width: 250px;
}

.menuContainer {
  position: fixed;
  width: 250px;
}

.menu {
  display: block;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px;
}

.menuItemTitle {
  color: rgba(0, 0, 0, 0.87);
}

.submenu {
  display: grid;
  gap: 10px;
  text-decoration: none;
  padding: 5px;
}

.submenuLink {
  display: grid;
  justify-content: start;
  padding: 5px 5px 5px 25px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6)
}

.menu:hover,
.submenuLink:hover,
.selected {
  border: 2px;
  background-color: var(--color-main);
  color: white;
  border-radius: 3px;
}

.title {
  font-weight: bold;
  text-transform: uppercase;
}

.titleSelected,
.title:hover {
  color: white;
}

.innerContainer:not(:last-child) {
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(199 199 199);
}

.innerContainer:not(:first-child) {
  padding-top: 10px;
}
