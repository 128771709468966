/**
 * Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.averageEliteReviewTime {
  font-weight: 700;
  background-color: var(--color-team-elite);
  color: var(--color-team-elite-contrast-color);
}

.averageStrongReviewTime {
  font-weight: 700;
  background-color: var(--color-team-strong);
  color: var(--color-team-strong-contrast-color);
}

.averageFairReviewTime {
  font-weight: 700;
  background-color: var(--color-team-fair);
  color: var(--color-team-fair-contrast-color);
}

.averageNeedsFocusReviewTime {
  font-weight: 700;
  background-color: var(--color-team-need-focus);
  color: var(--color-team-need-focus-contrast-color);
}


.tableCell {
  color: inherit;
}
