/**
 * Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

:root {
  --color-main: #005f73;
  --color-team-elite: #0a9396;
  --color-team-elite-contrast-color: white;
  --color-team-strong: #ee9b00;
  --color-team-strong-contrast-color: white;
  --color-team-fair: #bb3e03;
  --color-team-fair-contrast-color: white;
  --color-team-need-focus: #9b2226;
  --color-team-need-focus-contrast-color: white;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
