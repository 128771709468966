/**
 * Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.main {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  padding: 50px 30px;
  background-color: rgb(247, 247, 247);
}

.footer {
  margin-top: 30px;
}
