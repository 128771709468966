/**
 * Copyright (C) 2019-2022 Explore.dev - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

.container {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  justify-content: start;
  align-items: start;
  margin-left: -50px;
}

.header {
  display: grid;
  gap: 5px;
  align-items: center;
  margin-top: 50px;
}

.divider {
  height: 10px;
  background-color: var(--color-main);
}

.mainHeader {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  justify-content: start;
  align-items: center;
  margin-left: -50px;
}

.title {
  font-weight: 600;
  color: var(--color-main);
  font-family: 'Public Sans', sans-serif;
  text-transform: uppercase;
}

.anchor {
  visibility: hidden;
}

.header:hover .anchor {
  visibility: visible;
}

.icon {
  transform: rotate(-45deg);
}
