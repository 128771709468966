/**
 * Copyright 2022 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.container {
  display: grid;
  position: relative;
}

.takeActionLink {
  text-decoration: none;
}

.takeAction {
  bottom: 0;
  position: fixed;
  color: white;
  background-color: #ca6702;
  padding: 7px;
  text-decoration: none;
  text-align: center;
  transition: all 0.5s ease-in;
  width: 100%;
  left: 0;
  height: 80px;
  align-items: center;
  display: grid;
}

.takeAction:hover {
  background-color: #bb3e03;
  transition: all 0.5s ease-in;
}

.tableOfContents {
  display: none;
  position: absolute;
  left: -280px;
}

@media (min-width: 1500px) {
  .tableOfContents {
    display: block;
  }
}
